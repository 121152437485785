import dayjs from '../config/dayjs';

const fakeRows = [
  {
    id: '1',
    folio: '21',
    dteType: {
      name: 'Factura afecta',
      code: '33',
    },
    receiver: {
      id: '19892477',
      name: 'Fundación Feliz',
      rut: 68273364,
    },
    dateIssued: dayjs().subtract(8, 'days'),
    amountWithIva: 490990,
    siiStatus: 'REJECTED_CONTENT',
    chat: 'Chat',
    uniqueDocumentFinanceState: [],
    receptionDate: dayjs(),
  },
  {
    id: '2',
    folio: '42',
    dteType: {
      name: 'Factura afecta',
      code: '33',
    },
    receiver: {
      id: '19892477',
      name: 'Telefonía Celular SPA',
      rut: 76253142,
    },
    dateIssued: dayjs().subtract(22, 'days'),
    amountWithIva: 1900000,
    siiStatus: 'REJECTED_RECEIVER',
    chat: 'Chat',
    uniqueDocumentFinanceState: [],
    receptionDate: dayjs(),
  },
  {
    id: '3',
    folio: '33',
    dteType: {
      name: 'Factura afecta',
      code: '33',
    },
    receiver: {
      id: '19892477',
      name: 'Servicios TI Santiago',
      rut: 79273345,
    },
    dateIssued: dayjs(),
    amountWithIva: 1099990,
    siiStatus: 'REJECTED_BY_SII',
    chat: 'Chat',
    uniqueDocumentFinanceState: [],
    receptionDate: dayjs(),
  },
  {
    id: '4',
    folio: '49',
    dteType: {
      name: 'Factura afecta',
      code: '33',
    },
    receiver: {
      id: '19892477',
      name: 'Catering Services ltda',
      rut: 50902345,
    },
    dateIssued: dayjs().subtract(40, 'days'),
    amountWithIva: 5728900,
    siiStatus: 'REJECTED_TOTAL',
    chat: 'Chat',
    uniqueDocumentFinanceState: [],
    receptionDate: dayjs(),
  },
  {
    id: '5',
    folio: '41',
    dteType: {
      name: 'Factura afecta',
      code: '33',
    },
    receiver: {
      id: '19892477',
      name: 'Servicios de Gasfitería',
      rut: 61696456,
    },
    dateIssued: dayjs().subtract(15, 'days'),
    amountWithIva: 165780,
    siiStatus: 'RECEPTION_ACKNOWLEDGED',
    chat: 'Chat',
    uniqueDocumentFinanceState: [
      {
        id: '12',
        paymentDate: dayjs(),
        status: { id: '7', code: '7', status: 'Pagada completamente' },
      },
    ],
  },
  {
    id: '6',
    folio: '79',
    dteType: {
      name: 'Factura afecta',
      code: '33',
    },
    receiver: {
      id: '19892477',
      name: 'Universidad Nacional',
      rut: 77878898,
    },
    dateIssued: dayjs().subtract(20, 'days'),
    amountWithIva: 5728900,
    siiStatus: 'RECEPTION_ACKNOWLEDGED',
    chat: 'Chat',
    uniqueDocumentFinanceState: [
      {
        id: '12',
        paymentDate: dayjs().subtract(2, 'days'),
        status: { id: '7', code: '7', status: 'Pagada completamente' },
      },
    ],
  },
  {
    id: '7',
    folio: '86',
    dteType: {
      name: 'Factura afecta',
      code: '33',
    },
    receiver: {
      id: '19892477',
      name: 'Instituto del Mar',
      rut: 55654788,
    },
    dateIssued: dayjs().subtract(35, 'days'),
    amountWithIva: 350000,
    siiStatus: 'RECEPTION_ACKNOWLEDGED',
    chat: 'Chat',
    uniqueDocumentFinanceState: [
      {
        id: '12',
        paymentDate: dayjs().subtract(15, 'days'),
        status: { id: '7', code: '7', status: 'Pagada completamente' },
      },
    ],
  },
  {
    id: '8',
    folio: '97',
    dteType: {
      name: 'Factura afecta',
      code: '33',
    },
    receiver: {
      id: '19892477',
      name: 'Corporación Rancagua',
      rut: 66321456,
    },
    dateIssued: dayjs().subtract(13, 'days'),
    amountWithIva: 220990,
    siiStatus: 'VALID',
    status: 'CREATED',
    chat: 'Chat',
    uniqueDocumentFinanceState: [],
    receptionDate: dayjs(),
  },
  {
    id: '9',
    folio: '118',
    dteType: {
      name: 'Factura afecta',
      code: '33',
    },
    receiver: {
      id: '19892477',
      name: 'Transportes y Logística Ltda',
      rut: 88564789,
    },
    dateIssued: dayjs().subtract(5, 'days'),
    amountWithIva: 840000,
    siiStatus: 'VALID',
    chat: 'Chat',
    uniqueDocumentFinanceState: [],
    receptionDate: dayjs().subtract(4, 'days'),
  },
];

export default fakeRows;
