/* eslint-disable import/prefer-default-export */
import { red, orange, green } from '@mui/material/colors';
import dayjs from '../config/dayjs';

const diffDaysToColor = (date) => {
  const today = dayjs().startOf('day');
  const daysDiff = date.diff(today, 'days');
  if (daysDiff < -1) {
    return red[500];
  }
  if (daysDiff < 5) {
    return orange[500];
  }
  return green[500];
};

export default diffDaysToColor;
